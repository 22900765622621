$primary: #d00000;
$body-color: #11232d;

//$border-radius: .4rem;

// Import all of Bootstrap's CSS
@import "bootstrap/scss/bootstrap";

.cover-container {
    max-width: 42em;
}